import { type IntlShape } from 'react-intl';
import { toATLocalString } from '@alltrails/language/utils/toATLocalString';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { elevationFormatted, metersToFormattedUserUnits } from '@alltrails/filters/utils/mapStats';
import { FaqFilterType } from '../types';
import { FAQTrail, LocationDetailsResponseType } from '../../../types/locationDetailsPages/shared';
import { TrailFaqLink } from '../FaqLink';
import { BestTrailAnswerWithFilter, getBestTrailQuestionString } from './BestTrailMessages';

export const getBestTrailQuestion = (intl: IntlShape, placeName: string, locationType: LocationDetailsResponseType, filter?: FaqFilterType) => {
  // POI filter pages do not exist, so no variations of this question are necessary
  if (locationType === 'poi') {
    return intl.formatMessage({ defaultMessage: 'Where is the best place to hike near {placeName}?' }, { placeName });
  }

  return getBestTrailQuestionString(intl, placeName, filter);
};

type BestTrailAnswerProps = {
  filter?: FaqFilterType;
  placeName: string;
  bestTrail: FAQTrail;
  locationType: LocationDetailsResponseType;
  languageRegionCode: LanguageRegionCode;
  displayMetric: boolean;
  intl: IntlShape;
};

export const BestTrailAnswer = ({ filter, placeName, bestTrail, locationType, languageRegionCode, displayMetric, intl }: BestTrailAnswerProps) => {
  const { starRating, reviewCount, elevationGain, length } = bestTrail;
  const displayCount = toATLocalString(reviewCount, languageRegionCode);

  const displayElevationGain = elevationFormatted(elevationGain, displayMetric, languageRegionCode);
  const displayLength = metersToFormattedUserUnits(length, 1, displayMetric, languageRegionCode);
  const rating = typeof starRating === 'number' ? starRating : parseFloat(starRating);
  const displayStarRating = toATLocalString(rating, languageRegionCode, 1);

  return (
    <span>
      {locationType === 'poi' ? (
        intl.formatMessage(
          {
            defaultMessage:
              'According to users from AllTrails.com, the best place to hike near {placeName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
          },
          {
            placeName,
            trailName: <TrailFaqLink trail={bestTrail} languageRegionCode={languageRegionCode} />,
            starRating: displayStarRating,
            displayCount
          }
        )
      ) : (
        <BestTrailAnswerWithFilter
          filter={filter}
          parkName={placeName}
          trailName={<TrailFaqLink trail={bestTrail} languageRegionCode={languageRegionCode} />}
          starRating={displayStarRating}
          displayCount={displayCount}
          intl={intl}
        />
      )}{' '}
      {intl.formatMessage(
        {
          defaultMessage: 'This trail is {displayLength} long with an elevation gain of {displayElevationGain}.'
        },
        {
          displayLength,
          displayElevationGain
        }
      )}
    </span>
  );
};
