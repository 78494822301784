import { useMemo, useState } from 'react';
import classNames from 'classnames';
import debounce from 'lodash.debounce';
import dynamic from 'next/dynamic';
import { FormattedMessage } from 'react-intl';
import Link from '@alltrails/denali/components/Link';
import Gallery from '@alltrails/denali/icons/Gallery';
import { LightboxPhoto, LightboxUser } from '@alltrails/shared/types/lightbox';
import ReviewPhoto from '@alltrails/shared/types/ReviewPhoto';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { PhotoSize } from '@alltrails/shared/types/photos';
import type Review from '@alltrails/shared/types/review';
import { getPhotoUrl } from '@alltrails/shared/utils/requests/photoRequests';
import type Context from '@alltrails/core/types/Context';
import styles from './styles/styles.module.scss';
import TrailReviewPhoto from './TrailReviewPhoto';

const Lightbox = dynamic(() => import('@alltrails/lightbox').then(mod => mod.Lightbox), { ssr: false });

type TrailReviewPhotosProps = {
  className?: string;
  languageRegionCode: LanguageRegionCode;
  onLightboxClose?: () => void;
  onLightboxPhotoViewed?: (review: Review, photo: LightboxPhoto['photo']) => void;
  onLightboxUserClick?: (user: LightboxUser) => void;
  onReviewPhotosScroll?: (review: Review, numPhotos: number) => void;
  onReviewPhotosScrollEnd?: (review: Review, numPhotos: number) => void;
  onReviewPhotoClick?: (review: Review, photo: ReviewPhoto) => void;
  review: Review & { hasMorePhotos?: boolean };
  context?: Context;
  linkToActivity?: string;
};

const TrailReviewPhotos = ({
  className,
  languageRegionCode,
  onLightboxClose,
  onLightboxPhotoViewed,
  onLightboxUserClick,
  onReviewPhotosScroll,
  onReviewPhotosScrollEnd,
  onReviewPhotoClick,
  review,
  context,
  linkToActivity
}: TrailReviewPhotosProps): JSX.Element => {
  const { photos, hasMorePhotos } = review;
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [startingIdx, setStartingIdx] = useState(0);
  const [showLeftOverflowFade, setShowLeftOverflowFade] = useState(false);
  const [showRightOverflowFade, setShowRightOverflowFade] = useState(true);
  const lightboxImages = useMemo(
    () =>
      photos!.map(photo => ({
        id: photo.id,
        url: getPhotoUrl(photo, PhotoSize.ExtraLarge, true)
      })),
    [photos]
  );

  const isScrolledToRightEnd = (target: HTMLDivElement) => {
    const buffer = 2;
    const scrollLength = target.scrollWidth - target.offsetWidth;
    return scrollLength - target.scrollLeft < buffer;
  };

  const handleOverflowFades = (ev: React.UIEvent<HTMLDivElement>) => {
    const target = ev.target as HTMLDivElement;
    const scrolledToRightEnd = isScrolledToRightEnd(target);
    if (scrolledToRightEnd && showRightOverflowFade) {
      setShowRightOverflowFade(false);
    } else if (!scrolledToRightEnd && !showRightOverflowFade) {
      setShowRightOverflowFade(true);
    }

    const scrolledToLeftEnd = target.scrollLeft === 0;
    if (scrolledToLeftEnd && showLeftOverflowFade) {
      setShowLeftOverflowFade(false);
    } else if (!scrolledToLeftEnd && !showLeftOverflowFade) {
      setShowLeftOverflowFade(true);
    }
  };

  const handleOnScrolls = debounce((ev: React.UIEvent<HTMLDivElement>) => {
    const numPhotos = photos!.length;
    onReviewPhotosScroll?.(review, numPhotos);
    const target = ev.target as HTMLDivElement;
    if (isScrolledToRightEnd(target)) {
      onReviewPhotosScrollEnd?.(review, numPhotos);
    }
  }, 200);

  const onScroll = (ev: React.UIEvent<HTMLDivElement>) => {
    handleOverflowFades(ev);
    handleOnScrolls(ev);
  };

  const onPhotoFocus = (ev: React.FocusEvent<HTMLButtonElement>) => {
    ev.currentTarget.scrollIntoView({ behavior: 'smooth', inline: 'nearest', block: 'nearest' });
  };

  const onPhotoClick = (photo: ReviewPhoto, idx: number, ev: React.MouseEvent<HTMLButtonElement>) => {
    onReviewPhotoClick?.(review, photo);
    setStartingIdx(idx);
    setLightboxOpen(true);
    ev.currentTarget.scrollIntoView({ behavior: 'smooth', inline: 'nearest', block: 'nearest' });
  };

  const onPhotoViewed = (photo: LightboxPhoto['photo']) => {
    onLightboxPhotoViewed?.(review, photo);
  };

  const onLightboxCloseInternal = () => {
    onLightboxClose?.();
    setLightboxOpen(false);
  };

  const truncatedPhotos = photos!.slice(0, 10);

  return (
    <div
      className={classNames(styles.photosContainer, {
        [styles.photosOverflowRight]: showRightOverflowFade,
        [styles.photosOverflowLeft]: showLeftOverflowFade
      })}
      data-testid="trail-review-photos"
    >
      <div className={classNames(styles.photoCarousel, className)} onScroll={onScroll}>
        {truncatedPhotos!.map((photo: ReviewPhoto, idx: number) => (
          <TrailReviewPhoto
            key={photo.id}
            idx={idx}
            onPhotoClick={onPhotoClick}
            onPhotoFocus={onPhotoFocus}
            photo={photo}
            photoCount={photos!.length}
          />
        ))}
        {hasMorePhotos && linkToActivity && (
          <Link className={styles.showAllPhotos} href={linkToActivity} outsideOfMugen noUnderline>
            <Gallery />
            <FormattedMessage defaultMessage="Show all" />
          </Link>
        )}
      </div>
      {lightboxOpen && (
        <Lightbox
          context={context}
          languageRegionCode={languageRegionCode}
          closeModal={onLightboxCloseInternal}
          onPhotoViewed={onPhotoViewed}
          onUserClick={onLightboxUserClick}
          photos={lightboxImages}
          startingIndex={startingIdx}
        />
      )}
    </div>
  );
};

export default TrailReviewPhotos;
