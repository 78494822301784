'use client';

import { FormattedMessage, useIntl } from 'react-intl';
import logGuideClicked from '@alltrails/analytics/events/logGuideClicked';
import logGuidesSwiped from '@alltrails/analytics/events/logGuidesSwiped';
import PlusBadge from '@alltrails/denali/components/PlusBadge';
import Carousel from '@alltrails/denali/components/Carousel';
import { GuideCard } from '@alltrails/cards';
import { GuideHit } from '@alltrails/search/types/algoliaResultTypes';
import { useLanguageRegionCode } from '@alltrails/language/hooks/useLanguageRegionCode';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import { useCallback, useMemo, useRef } from 'react';
import GuideSourceType from '@alltrails/analytics/enums/GuideSourceType';
import PageSectionType from '@alltrails/analytics/enums/PageSectionType';
import useLogWebSectionViewed from '@alltrails/layout/hooks/useLogWebSectionViewed';
import { maxSlideWidth, slidesPerViewBreakpoints, slidesPerViewMobile } from '../carouselConstants';
import guidesIndexSrc from './guidesIndex.avif';
import styles from './styles/GuidesCarousel.module.scss';
import { LocationDetailsResponseType } from '@/types/locationDetailsPages/shared';
import { getAnalyticsPageType } from '@/utils/getAnalyticsPageType';
import { getGuideSourceType } from '@/utils/getGuideSourceType';

type GuidesCarouselProps = {
  guides: GuideHit[];
  parkId?: number;
  type: LocationDetailsResponseType;
};

const GuidesCarousel = ({ guides, parkId, type }: GuidesCarouselProps): JSX.Element => {
  const pageType = getAnalyticsPageType(type);
  const sourceType = getGuideSourceType(type);
  const languageRegionCode = useLanguageRegionCode();
  const intl = useIntl();
  const items = [...guides, { objectID: 'index' } as GuideHit];
  const carousel = useRef(false);
  const indexTitle = intl.formatMessage({ defaultMessage: 'Explore more national park guides' });

  const handleGuideCardClicked = useCallback(
    (id: number | string, index: number) => {
      logGuideClicked({
        guide_id: String(id),
        horizontal_index: index + 1,
        source_type: sourceType
      });
    },
    [sourceType]
  );

  const indexGuideCard = useMemo(
    () => (
      <GuideCard
        href={wrapUrlSafe('/guides/country', languageRegionCode)}
        target="_blank"
        onClick={() => handleGuideCardClicked('explore more national park guides', items.length - 1)}
        photoUrl={guidesIndexSrc}
        title={`<em>${indexTitle}</em>`}
        altText={indexTitle}
        maxWidth={maxSlideWidth}
      />
    ),
    [handleGuideCardClicked, indexTitle, items.length, languageRegionCode]
  );
  const ref = useRef<HTMLDivElement>(null);
  useLogWebSectionViewed(ref, PageSectionType.Guides, pageType, { threshold: 1 });

  return (
    <div className={styles.guidesContent} ref={ref}>
      <div className={styles.header}>
        <div className={styles.title}>
          <PlusBadge size="lg" className={styles.plusBadge} />
          <h2 className={styles.titleText}>
            <FormattedMessage defaultMessage="Guides" />
          </h2>
        </div>
      </div>
      <Carousel
        uniqueId="park-page-guides-carousel"
        showPaginationDots={false}
        items={items}
        renderItem={(guide, index) =>
          guide.objectID === 'index' ? (
            indexGuideCard
          ) : (
            <GuideCard
              href={wrapUrlSafe(`/${guide.slug}`, languageRegionCode)}
              target="_blank"
              onClick={() => handleGuideCardClicked(guide.ID, index)}
              photoUrl={`${guide?.photo_url}?size=1120w746h`}
              title={guide?.name_with_formatting || guide?.name}
              trailCount={guide?.trail_count || 0}
              maxWidth={maxSlideWidth}
            />
          )
        }
        getItemKey={guide => guide?.objectID}
        slidesPerViewMobile={slidesPerViewMobile}
        slidesPerViewBreakpoints={slidesPerViewBreakpoints}
        onSlideChange={() => {
          if (!carousel.current && parkId) {
            logGuidesSwiped({ park_id: String(parkId), source_type: GuideSourceType.LocationPage });
            carousel.current = true;
          }
        }}
      />
    </div>
  );
};

export default GuidesCarousel;
