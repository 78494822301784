import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import classNames from 'classnames';
import DOMPurify from 'isomorphic-dompurify';
import { FormattedMessage, useIntl } from 'react-intl';
import CommunityConnectActionLocation from '@alltrails/analytics/enums/CommunityConnectActionLocation';
import CommunityConnectRelationshipAction from '@alltrails/analytics/enums/CommunityConnectRelationshipAction';
import logCommunityConnectActionSelected from '@alltrails/analytics/events/logCommunityConnectActionSelected';
import PlaceTrail from '@alltrails/denali/icons/PlaceTrail';
import { getUrlFromSlug } from '@alltrails/shared/utils/cardLinks';
import StatusBadge from '@alltrails/shared/denali/components/StatusBadge';
import Tag from '@alltrails/denali/components/Tag';
import { UserLabel, TrailLabel, ActivityLabel } from '@alltrails/cards';
import First from '@alltrails/denali/icons/First';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import type { LightboxPhoto, LightboxUser } from '@alltrails/shared/types/lightbox';
import type ReviewPhoto from '@alltrails/shared/types/ReviewPhoto';
import type Review from '@alltrails/shared/types/review';
import type User from '@alltrails/core/types/User';
import type Context from '@alltrails/core/types/Context';
import SuccessToast from '@alltrails/shared/types/successToast';
import { timeAgo } from '@alltrails/shared/utils/timeHelpers';
import { useLocalizedText } from '@alltrails/shared/modules/Localization';
import { User as BelayUser, UserConnectionActionType } from '@alltrails/shared/types/belay';
import { getRelationshipAction } from '@alltrails/shared/utils/community/analyticsHelpers';
import compareUserIds from '@alltrails/shared/utils/compareUserIds';
import getActivityType from '@alltrails/shared/utils/getActivityType';
import useDifficultyText from '@alltrails/shared/hooks/useDifficultyText';
import LocalizationButton from '@alltrails/layout/components/LocalizationButton';
import Link from '@alltrails/denali/components/Link';
import { Typography } from '@alltrails/core';
import hasPermission from '@alltrails/shared/utils/hasPermission';
import { getObstacle } from '../../utils/obstacle';
import TrailReviewDate from './TrailReviewDate';
import TrailReviewPhotos from './TrailReviewPhotos';
import TrailReviewReply from './TrailReviewReply';
import TrailReviewStructuredData from './TrailReviewStructuredData';
import TrailReviewOverflowMenu from './TrailReviewOverflowMenu';
import styles from './styles/styles.module.scss';

export type TrailReviewProps = {
  index?: number;
  context?: Context;
  currentUser?: User;
  hideBadges?: boolean;
  hideObstacles?: boolean;
  hideReplies?: boolean;
  humanizeTime?: boolean;
  isEditable?: boolean;
  labelType?: 'user' | 'trail' | 'activity';
  languageRegionCode: LanguageRegionCode;
  nameExtension?: React.ReactNode;
  onActivityLinkClick?: () => void;
  onDeleteClick?: () => void;
  onEditClick?: () => void;
  onLightboxClose?: () => void;
  onLightboxPhotoViewed?: (review: Review, photo: LightboxPhoto['photo']) => void;
  onLightboxUserClick?: (user: LightboxUser) => void;
  onReviewPhotosScroll?: (review: Review, numPhotos: number) => void;
  onReviewPhotosScrollEnd?: (review: Review, numPhotos: number) => void;
  onReplyDeleteClick?: () => void;
  onReplySaveClick?: (replyText: string) => Promise<void>;
  onReviewPhotoClick?: (review: Review, photo: ReviewPhoto) => void;
  photosClassName?: string;
  preferHTML?: boolean;
  review: Review;
  onUserClick?: () => void;
  localizeOnLoad?: boolean;
  setIsReportingModalOpen?: Dispatch<SetStateAction<boolean>>;
  setReportProps?: Dispatch<SetStateAction<{ trailId: string; reviewId: string; reportUser: User | BelayUser } | null>>;
  setReportingSuccessToast?: Dispatch<SetStateAction<SuccessToast>>;
  onToggleSummaryExclusion?: (summaryExclusion: boolean) => void;
  photosOverflowOnMobile?: boolean;
  bottomLinkType?: 'trail' | 'activity';
  onTrailLinkClick?: () => void;
};

const TrailReview = ({
  index,
  currentUser,
  hideBadges,
  context,
  hideObstacles,
  hideReplies,
  humanizeTime,
  isEditable,
  labelType = 'user',
  languageRegionCode,
  nameExtension,
  onActivityLinkClick,
  onDeleteClick,
  onEditClick,
  onLightboxClose,
  onLightboxPhotoViewed,
  onLightboxUserClick,
  onReviewPhotosScroll,
  onReviewPhotosScrollEnd,
  onReplyDeleteClick,
  onReplySaveClick,
  onReviewPhotoClick,
  photosClassName,
  preferHTML,
  review,
  onUserClick,
  localizeOnLoad = false,
  setIsReportingModalOpen,
  setReportProps,
  setReportingSuccessToast,
  onToggleSummaryExclusion,
  photosOverflowOnMobile = true,
  bottomLinkType = 'activity',
  onTrailLinkClick
}: TrailReviewProps): JSX.Element => {
  const intl = useIntl();
  let localizeOptions;
  if (localizeOnLoad) {
    localizeOptions = {
      targetLangText: review?.comment,
      localizeByDefault: true
    };
  }
  const sourceText = review?.comment_source || review?.comment;
  const { canBeLocalized, toggleText, isLocalized, text } = useLocalizedText(
    sourceText,
    review?.comment_lang,
    languageRegionCode,
    {
      table: 'reviews',
      row: review?.id,
      column: 'comment'
    },
    localizeOptions
  );

  const [showReplyEditing, setShowReplyEditing] = useState(false);
  const [isSavingReply, setIsSavingReply] = useState(false);
  const [isMoreOptionsOpen, setIsMoreOptionsOpen] = useState(false);
  const difficultyText = useDifficultyText(review?.difficulty || 0, true);

  useEffect(() => {
    if (review && review.trailId && setReportProps) {
      setReportProps({ trailId: String(review.trailId), reviewId: String(review.id), reportUser: review.user });
    }
  }, [review, setReportProps]);

  const activityType = review.activity ? getActivityType(intl, review.activity.uid) : '';
  const activityText = activityType || review.activity?.name;
  let linkToRecording: string | null = null;
  if (
    review.associatedRecording &&
    review.user &&
    (hasPermission({ permission: 'trails:manage', user: currentUser }) || review.associatedRecording.contentPrivacy !== 'private')
  ) {
    linkToRecording = `/explore/recording/${review.associatedRecording.slug}`;
  }

  const shouldDisplayFirstReview = review.badges?.first;
  const shouldDisplaySummaryExclusion = hasPermission({ permission: 'trails:manage', user: currentUser }) && review.summaryExclusion;
  const shouldDisplayBadges: boolean = !hideBadges && (review.badges?.first || !!shouldDisplaySummaryExclusion);
  let firstReviewDiv: JSX.Element | null = null;
  if (shouldDisplayBadges && shouldDisplayFirstReview) {
    firstReviewDiv = (
      <div className={styles.badgeContainer}>
        <First color="--color-text-primary" size="sm" />
        <span>
          <FormattedMessage defaultMessage="First to review" />
        </span>
      </div>
    );
  }

  let summaryExclusionBadge: JSX.Element | null = null;
  if (shouldDisplayBadges && shouldDisplaySummaryExclusion) {
    summaryExclusionBadge = (
      <StatusBadge
        className={styles.summaryExclusion}
        variant="info"
        text={intl.formatMessage({ defaultMessage: 'Review not included in summary' })}
        minimal
      />
    );
  }

  let obstacles: string[] = [];
  if (review.obstacles) {
    let isGreat = false;
    const sortedObs: string[] = [];
    review.obstacles.forEach(obstacle => {
      if (obstacle.uid !== 'great') {
        sortedObs.push(getObstacle(intl, obstacle.uid));
      } else {
        isGreat = true;
      }
    });
    sortedObs.sort();
    // append 'great' obstacle at the end
    obstacles = isGreat ? [...sortedObs, getObstacle(intl, 'great')] : sortedObs;
  }

  let commentDiv: JSX.Element | null = null;
  if (preferHTML && review.comment_html && !canBeLocalized) {
    const sanitizeConfig = {
      ALLOWED_TAGS: ['strong'] // review.comment_html only supports bolding
    };
    const sanitizedHTML = DOMPurify.sanitize(review.comment_html, sanitizeConfig);
    commentDiv = <p className={styles.reviewText} dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
  } else {
    commentDiv = <p className={styles.reviewText}>{text}</p>;
  }

  const logConnectActionSelected = (relationshipAction: CommunityConnectRelationshipAction) => {
    logCommunityConnectActionSelected({
      relationship_action: relationshipAction,
      carousel_position: -1, // never in carousel, -1 for "null" value
      item_location: CommunityConnectActionLocation.TrailPageReview,
      item_position: index,
      source_user_id: `${currentUser?.id}`,
      target_user_id: `${review.user.id}`
    });
  };

  const onConnectClick = (actionType: UserConnectionActionType) => {
    logConnectActionSelected(getRelationshipAction(actionType));
  };

  const shouldRenderAttributes = !hideObstacles && (review.obstacles.length > 0 || review.infoAttributes?.length > 0 || review.difficulty);
  const includeReviewMeta = !!review.trailName && !!review.rating;

  return (
    <div>
      {includeReviewMeta && <TrailReviewStructuredData review={review} localizedText={text} languageRegionCode={languageRegionCode} />}
      <div className={styles.reviewUser}>
        {labelType === 'user' && (
          <UserLabel
            nameExtension={nameExtension}
            secondaryText={
              <span suppressHydrationWarning>
                <TrailReviewDate created={review.metadata.created} languageRegionCode={languageRegionCode} shouldHumanize={humanizeTime} />
              </span>
            }
            tertiaryText={activityText}
            user={review.user}
            onClick={onUserClick}
            ratingValue={review.rating}
          />
        )}
        {labelType === 'trail' && (
          <TrailLabel
            languageRegionCode={languageRegionCode}
            secondaryText={timeAgo(review.metadata.created, languageRegionCode)}
            tertiaryText={activityText}
            trail={{
              id: review.trailId || 0,
              name: review.trailName || '',
              slug: review.trailSlug
            }}
          />
        )}
        {labelType === 'activity' && (
          <ActivityLabel
            languageRegionCode={languageRegionCode}
            secondaryText={timeAgo(review.metadata.created, languageRegionCode)}
            tertiaryText={activityText}
            activity={{
              name: review.associatedRecording?.name || '',
              slug: review.associatedRecording?.slug || ''
            }}
          />
        )}
        {currentUser && setIsReportingModalOpen && (
          <TrailReviewOverflowMenu
            isThirdPartyReview={!compareUserIds(review.user.id, currentUser?.id)}
            isMoreOptionsOpen={isMoreOptionsOpen}
            setIsMoreOptionsOpen={setIsMoreOptionsOpen}
            onConnectClick={onConnectClick}
            setIsReportingModalOpen={setIsReportingModalOpen}
            canComment={hasPermission({ permission: 'trails:manage', user: currentUser }) && !compareUserIds(review.user.id, currentUser?.id)}
            hideReplies={hideReplies}
            isEditable={isEditable}
            isAdmin={hasPermission({ permission: 'trails:manage', user: currentUser })}
            onCommentClick={() => setShowReplyEditing(true)}
            onDeleteClick={onDeleteClick}
            onEditClick={onEditClick}
            setReportingSuccessToast={setReportingSuccessToast}
            summaryExclusion={review.summaryExclusion}
            onToggleSummaryExclusion={onToggleSummaryExclusion}
            reviewUserId={review.user.id}
          />
        )}
      </div>
      {review.ratingAttributes?.length > 0 && (
        <div className={styles.ratingTags}>
          {review.ratingAttributes?.map(attribute => <Tag key={attribute.uid} testId={attribute.uid} text={attribute.name} size="sm" />)}
        </div>
      )}
      <div
        className={classNames(styles.reviewBody, {
          [styles.reviewBodyFirst]: shouldDisplayBadges
        })}
      >
        {summaryExclusionBadge}
        {firstReviewDiv}
        {commentDiv}
      </div>
      {!!canBeLocalized && <LocalizationButton className={styles.translationButton} showSource={!isLocalized} toggleShowSource={toggleText} />}
      {!!shouldRenderAttributes && (
        <div className={styles.attributes}>
          {review.obstacles.length > 0 && (
            <div>
              <span className={styles.attribute}>
                <Typography variant="text200bold" className={styles.attributeTitle}>
                  <FormattedMessage defaultMessage="Conditions:" />{' '}
                </Typography>
                <Typography variant="text200">{review.obstacles.map(obstacle => obstacle.name).join(', ')}</Typography>
              </span>
            </div>
          )}
          {review.infoAttributes?.length > 0 && (
            <div>
              <span>
                <Typography variant="text200bold" className={styles.attributeTitle}>
                  <FormattedMessage defaultMessage="Parking:" />{' '}
                </Typography>
                <Typography variant="text200">{review.infoAttributes.map(attribute => attribute.name).join(', ')}</Typography>
              </span>
            </div>
          )}
          {!!review.difficulty && (
            <div>
              <span>
                <Typography variant="text200bold" className={styles.attributeTitle}>
                  <FormattedMessage defaultMessage="Difficulty:" />{' '}
                </Typography>
                <Typography variant="text200">{difficultyText}</Typography>
              </span>
            </div>
          )}
        </div>
      )}
      {review.photos && !!review.photos.length && (
        <TrailReviewPhotos
          className={classNames(photosClassName, styles.newPhotoCarousel, { [styles.photosOverflowOnMobile]: photosOverflowOnMobile })}
          languageRegionCode={languageRegionCode}
          onLightboxClose={onLightboxClose}
          onLightboxPhotoViewed={onLightboxPhotoViewed}
          onLightboxUserClick={onLightboxUserClick}
          onReviewPhotosScroll={onReviewPhotosScroll}
          onReviewPhotosScrollEnd={onReviewPhotosScrollEnd}
          onReviewPhotoClick={onReviewPhotoClick}
          review={review}
          context={context}
          linkToActivity={linkToRecording || undefined}
        />
      )}
      <TrailReviewReply
        hideReplies={hideReplies}
        isAdmin={hasPermission({ permission: 'trails:manage', user: currentUser })}
        isEditing={showReplyEditing}
        isSaving={isSavingReply}
        languageRegionCode={languageRegionCode}
        onCancel={() => setShowReplyEditing(false)}
        onDelete={onReplyDeleteClick}
        onSave={async (replyText: string) => {
          setIsSavingReply(true);
          await onReplySaveClick?.(replyText);
          setShowReplyEditing(false);
          setIsSavingReply(false);
        }}
        reviewReply={review.replies?.[0]} // only show 1 reply
      />
      {bottomLinkType === 'activity' && linkToRecording && (
        <Link href={linkToRecording} target="_blank" onClick={onActivityLinkClick} className={styles.bottomLink}>
          <FormattedMessage defaultMessage="Show activity" />
        </Link>
      )}
      {bottomLinkType === 'trail' && (
        <Link
          href={getUrlFromSlug(`trail/${review.trailSlug}`, '')}
          target="_blank"
          testId="trail-link"
          onClick={onTrailLinkClick}
          className={styles.bottomLink}
          outsideOfMugen
        >
          <PlaceTrail size="sm" />
          {review.trailName}
        </Link>
      )}
    </div>
  );
};

export default TrailReview;
