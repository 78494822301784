import Icon from '../components/Icon';

const TrailWheelchair = Icon(
  'trail-wheelchair',
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.028 5.226A2.75 2.75 0 1 1 9.972.654a2.75 2.75 0 0 1 3.056 4.572Zm-.834-3.325a1.25 1.25 0 1 0-1.389 2.079 1.25 1.25 0 0 0 1.39-2.08ZM19.31 19.09h2.25c.398 0 .75.352.75.75s-.352.75-.75.75h-.95c.24.628.162 1.355-.186 1.93a2.288 2.288 0 0 1-2.095 1.075c-1.28-.098-2.254-1.317-2.05-2.597.13-.82.74-1.533 1.531-1.788v-.31h-2.26a6.24 6.24 0 0 1-12.224-2.403A6.29 6.29 0 0 1 6.92 11.66V8.83A1.94 1.94 0 0 0 5 6.9H2.2a.772.772 0 0 1-.75-.75c0-.398.352-.75.75-.75H5a3.439 3.439 0 0 1 2.63 1.3 2.29 2.29 0 0 1 1.54-.61 2.26 2.26 0 0 1 2.24 2H14c1.236-.017 2.28 1.014 2.28 2.25a2.32 2.32 0 0 1-.13.75h4.65c.398 0 .75.352.75.75s-.352.75-.75.75h-1.49v6.5ZM8.42 8.31v2.78H14c.398 0 .75-.352.75-.75a.772.772 0 0 0-.75-.75h-3.33a.75.75 0 0 1-.53-.22c-.283-.283-.22-.695-.22-1.06a.772.772 0 0 0-.75-.75.772.772 0 0 0-.75.75ZM6.861 21.29c.781.521 1.7.8 2.64.8 2.586 0 4.75-2.163 4.75-4.75 0-1.9-1.177-3.661-2.933-4.389a4.75 4.75 0 0 0-2.744-.27 4.792 4.792 0 0 0-3.733 3.732 4.792 4.792 0 0 0 2.02 4.876Zm8.89-3.95v.06h2.06v-4.81h-4.26a6.318 6.318 0 0 1 2.2 4.75Zm2.75 4.75a.782.782 0 0 0 .75-.75.76.76 0 0 0-.897-.736.764.764 0 0 0-.59.59.76.76 0 0 0 .736.896Z"
    />
  </>
);

export default TrailWheelchair;
