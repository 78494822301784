'use client';

import { useState, useRef } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import { Typography } from '@alltrails/core';
import useIsMobileSizedScreen from '@alltrails/denali/hooks/useIsMobileSizedScreen';
import classNames from 'classnames';
import PageType from '@alltrails/analytics/enums/PageType';
import PageSectionType from '@alltrails/analytics/enums/PageSectionType';
import useLogWebSectionViewed from '@alltrails/layout/hooks/useLogWebSectionViewed';
import usePublicBaseUrl from '@alltrails/context/hooks/usePublicBaseUrl';
import LinkAccordian from '../LinkAccordian';
import { SeoLinksSectionKey, SeoLinks as SeoLinksType } from '../../types/locationDetailsPages/shared';
import DesktopLinkSection from './DesktopLinkSection';
import styles from './SeoLinks.module.scss';

const SEO_SECTION_STRINGS = defineMessages({
  TRAILS: {
    defaultMessage: 'Top {count} trails'
  },
  PARKS: {
    defaultMessage: 'Top {count} parks'
  },
  CITIES: {
    defaultMessage: 'Top {count} cities'
  },
  REGIONS: {
    defaultMessage: 'Top {count} regions'
  },
  STATES: {
    defaultMessage: 'Top {count} states'
  }
});

type LinkSectionProps = {
  links: { label: string; href: string }[];
  title: string;
  isMobileExpanded?: boolean;
};

const LinkSection = ({ links, title, isMobileExpanded = false }: LinkSectionProps) => {
  const [isOpen, setIsOpen] = useState(isMobileExpanded);
  const isMobile = useIsMobileSizedScreen();

  return isMobile ? (
    <LinkAccordian title={title} isOpen={isOpen} toggleIsOpen={() => setIsOpen(!isOpen)} links={links} />
  ) : (
    <div className={styles.desktopLinkSection}>
      <DesktopLinkSection title={title} links={links} />
    </div>
  );
};

type SeoLinksProps = {
  locationName: string;
  linkData: {
    key: SeoLinksSectionKey;
    data: SeoLinksType;
  }[];
  isBot?: boolean;
  isLastElement: boolean;
  pageType?: PageType;
};

const SeoLinks = ({ locationName, linkData, isBot = false, isLastElement, pageType }: SeoLinksProps) => {
  const { formatMessage } = useFormatMessage(SEO_SECTION_STRINGS);
  const ref = useRef<HTMLDivElement>(null);
  useLogWebSectionViewed(ref, PageSectionType.LinkBlock, pageType, { threshold: 0.4 });
  const publicBaseUrl = usePublicBaseUrl();

  return (
    <div className={classNames(styles.container, isLastElement && styles.linksPadding)} ref={ref}>
      <div className={styles.content}>
        <Typography component="h2" variant="heading600" className={styles.title}>
          <FormattedMessage defaultMessage="Explore {locationName}" values={{ locationName }} />
        </Typography>
        <div className={styles.linkSection}>
          {linkData.map((section, i) => {
            if (section.data.length === 0) return null;
            const links = section.data.slice(0, 15); // The max number of links we display is 15
            const mappedLinks = links.map(link => ({ label: link.title, href: `${publicBaseUrl}${link.url}` }));
            const heading = formatMessage(section.key, { count: links.length });
            return <LinkSection links={mappedLinks} title={heading} key={section.key} isMobileExpanded={isBot} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default SeoLinks;
