import { type IntlShape } from 'react-intl';
import { toATLocalString } from '@alltrails/language/utils/toATLocalString';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { formatData } from '@alltrails/filters/utils/mapStats';
import { FaqFilterType } from '../types';
import { FAQParkAggregatesType } from '../../../types/locationDetailsPages/shared';
import { ParkAggregatesAnswerWithFilter, getParkAggregatesQuestionStrings } from './ParkAggregateMessages';

export const getParkAggregatesQuestion = (intl: IntlShape, locationName: string, filter?: FaqFilterType) => {
  return getParkAggregatesQuestionStrings(intl, locationName, filter);
};

type ParkAggregatesAnswerProps = {
  filter: FaqFilterType;
  locationName: string;
  faqParkAggregates: FAQParkAggregatesType;
  intl: IntlShape;
  languageRegionCode: LanguageRegionCode;
};

export const ParkAggregatesAnswer = ({ filter, locationName, faqParkAggregates, intl, languageRegionCode }: ParkAggregatesAnswerProps) => {
  const { totalTrails, averageRating, reviewCount } = faqParkAggregates;
  const displayAverageRating = formatData(averageRating, 1, null, languageRegionCode);
  return (
    <ParkAggregatesAnswerWithFilter
      filter={filter}
      locationName={locationName}
      trailCount={toATLocalString(totalTrails, languageRegionCode)}
      rating={displayAverageRating}
      reviewCount={toATLocalString(reviewCount, languageRegionCode)}
      intl={intl}
    />
  );
};
