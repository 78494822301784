'use client';

import { ReactNode, useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import Alert from '@alltrails/shared/types/alert';
import ClosureFilled from '@alltrails/denali/icons/ClosureFilled';
import CautionFilled from '@alltrails/denali/icons/CautionFilled';
import PageType from '@alltrails/analytics/enums/PageType';
import logLocationPageAlertViewed from '@alltrails/analytics/events/logLocationPageAlertViewed';
import Link from '@alltrails/denali/components/Link';
import styles from './Alerts.module.scss';
import { ParkSection } from '@/types/locationDetailsPages/shared';

const AlertsModal = dynamic(() => import('./AlertsModal'), { ssr: false });

type Props = {
  alertBannerSection?: ParkSection;
  alerts: Array<Alert & { description: ReactNode }>;
  pageType: PageType;
};

const Alerts = ({ alertBannerSection, alerts, pageType }: Props) => {
  const [isAlertsModalOpen, setIsAlertsModalOpen] = useState(false);

  const isHighPriorityAlert = alertBannerSection?.priorityType === 'high';
  const isLowPriorityAlert = alertBannerSection?.priorityType === 'low';

  useEffect(() => {
    if (alerts.length === 0) {
      return;
    }

    logLocationPageAlertViewed({
      alert_type: alertBannerSection?.alertBanner?.bannerType,
      page_type: pageType,
      is_high_priority: isHighPriorityAlert
    });
  }, [alerts, pageType, alertBannerSection, isHighPriorityAlert]);

  if (alerts.length === 0) {
    return;
  }

  return (
    <>
      <div className={styles.inlineAlerts}>
        {(isHighPriorityAlert || isLowPriorityAlert) && (
          <div className={isHighPriorityAlert ? styles.highPriorityAlerts : styles.otherAlerts}>
            {isHighPriorityAlert ? <ClosureFilled color="--color-text-error" size="sm" /> : <CautionFilled color="--color-text-caution" size="sm" />}
            <Link
              className={isHighPriorityAlert ? styles.inlineAlertsLink : styles.inlineCautionAlertsLink}
              onClick={() => setIsAlertsModalOpen(true)}
              testId={isHighPriorityAlert ? 'high-priority-alerts' : 'low-priority-alerts'}
            >
              {alertBannerSection?.alertBanner?.heading}
            </Link>
            {alerts.length > 1 && <span> {alertBannerSection?.alertBanner?.subheading} </span>}
          </div>
        )}
        <span className={styles.dividerDot}>·</span>
      </div>
      {isAlertsModalOpen && <AlertsModal alerts={alerts} onClose={() => setIsAlertsModalOpen(false)} />}
    </>
  );
};

export default Alerts;
