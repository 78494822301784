'use client';

import { useIntl } from 'react-intl';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { Typography } from '@alltrails/core';
import styles from './LastUpdated.module.scss';

function lowerDayPeriod({ type, value }) {
  switch (type) {
    case 'dayPeriod':
      return value.toLowerCase();
    default:
      return value;
  }
}

type LastUpdatedProps = {
  languageRegionCode: LanguageRegionCode;
  updatedAt: string;
};

const LastUpdated = ({ languageRegionCode, updatedAt }: LastUpdatedProps) => {
  const intl = useIntl();
  const updated = new Date(updatedAt);

  let formattedDate;
  try {
    const formatter = new Intl.DateTimeFormat(languageRegionCode, {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'shortGeneric'
    });
    formattedDate = formatter.formatToParts(updated).map(lowerDayPeriod).join('');
  } catch (e) {
    const fallbackFormatter = new Intl.DateTimeFormat(languageRegionCode, {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short'
    });

    formattedDate = fallbackFormatter.formatToParts(updated).map(lowerDayPeriod).join('');
  }

  return (
    <Typography className={styles.lastUpdatedText} component="div" variant="text100">
      <span suppressHydrationWarning>{intl.formatMessage({ defaultMessage: 'Last updated on {date}' }, { date: formattedDate })}</span>
    </Typography>
  );
};

export default LastUpdated;
