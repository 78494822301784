import { useEffect, useState } from 'react';

const branchLinkBaseUrl = 'https://alltrails-alternate.app.link';

const useBaseBranchUrl = () => {
  const [didOptOut, setDidOptOut] = useState<boolean>(false);

  useEffect(() => {
    const handleOsanoConsentSaved = (consentObject: OsanoConsentObject) => {
      setDidOptOut(consentObject.ANALYTICS === 'DENY');
    };

    window.Osano?.cm?.addEventListener('osano-cm-consent-saved', handleOsanoConsentSaved);
  }, []);

  return `${branchLinkBaseUrl}?$do_not_process=${didOptOut}`;
};

export default useBaseBranchUrl;
