import { ReactNode } from 'react';
import { type IntlShape } from 'react-intl';
import { toATLocalString } from '@alltrails/language/utils/toATLocalString';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { FAQTrailType, LocationDetailsResponseType } from '../../../types/locationDetailsPages/shared';

export const getTypesOfTrailsQuestion = (intl: IntlShape, placeName: string, locationType: LocationDetailsResponseType) => {
  if (locationType === 'poi') {
    return intl.formatMessage({ defaultMessage: 'What are the different types of hiking trails near {name}?' }, { name: placeName });
  }
  return intl.formatMessage({ defaultMessage: 'What are the different types of hiking trails in {name}?' }, { name: placeName });
};

type TypesOfTrailsAnswerProps = {
  faqTrailTypes: FAQTrailType[];
  placeName: string;
  locationType: LocationDetailsResponseType;
  intl: IntlShape;
  languageRegionCode: LanguageRegionCode;
};

export const TypesOfTrailsAnswer = ({ faqTrailTypes, placeName, locationType, intl, languageRegionCode }: TypesOfTrailsAnswerProps) => {
  const trailTypes = faqTrailTypes.map(({ name: activityName, count }) => {
    const formattedCount = toATLocalString(count, languageRegionCode);
    return intl.formatMessage({ defaultMessage: '{formattedCount} {activityName} trails,' }, { activityName, formattedCount });
  });

  const joinedTrailTypes = trailTypes.map<ReactNode>(t => t).reduce((prev, curr) => [prev, ' ', curr], '');

  if (locationType === 'poi') {
    return intl.formatMessage(
      { defaultMessage: "There are plenty of things to do on hiking trails near {name}. On AllTrails.com, you'll find {trailTypes} and more." },
      {
        name: placeName,
        trailTypes: <>{joinedTrailTypes}</>
      }
    );
  }

  return intl.formatMessage(
    { defaultMessage: "There are plenty of things to do on {name}'s hiking trails. On AllTrails.com, you'll find {trailTypes} and more." },
    {
      name: placeName,
      trailTypes: <>{joinedTrailTypes}</>
    }
  );
};
