import Icon from '../components/Icon';

const Wallet = Icon(
  'wallet',
  <>
    <path d="M18 13.5a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0Z" />
    <path
      fillRule="evenodd"
      d="M4.72 5.47a.75.75 0 0 1 .53-.22H18a.75.75 0 0 0 0-1.5H5.25A2.25 2.25 0 0 0 3 6v12a2.25 2.25 0 0 0 2.25 2.25h15a1.5 1.5 0 0 0 1.5-1.5V8.25a1.5 1.5 0 0 0-1.5-1.5h-15a.75.75 0 0 1-.53-1.28ZM4.5 18V8.121c.239.085.492.129.75.129h15v10.5h-15A.75.75 0 0 1 4.5 18Z"
      clipRule="evenodd"
    />
  </>
);

export default Wallet;
