'use client';

import { ComponentProps, ReactElement, ReactNode, useId, useState } from 'react';
import classNames from 'classnames';
import { Typography, VerticalSlide } from '@alltrails/core';
import Chevron from '../../icons/Chevron';
import styles from './styles/styles.module.scss';

export type AccordionProps = {
  ariaLevel?: number;
  borderBottomContainerClassName?: string;
  buttonContainerClassName?: string;
  children: ReactElement[] | ReactElement | string;
  contentClassName?: string;
  externalControls?: {
    isOpen: boolean;
    toggleIsOpen: () => void;
  };
  headingTypography?: ComponentProps<typeof Typography>['variant'];
  initiallyOpen?: boolean;
  leadingVisual?: JSX.Element;
  subtitle?: ReactNode;
  title: ReactNode;
  trailingText?: string;
  variant?: 'default' | 'minimal';
};

export default function Accordion({
  ariaLevel = 3,
  borderBottomContainerClassName,
  children,
  buttonContainerClassName,
  contentClassName,
  externalControls,
  headingTypography,
  initiallyOpen = false,
  leadingVisual,
  subtitle,
  title,
  trailingText,
  variant = 'default'
}: AccordionProps) {
  const [internalIsOpen, setInternalIsOpen] = useState(initiallyOpen);

  const toggleOnClick = () => {
    if (externalControls) {
      externalControls.toggleIsOpen();
    } else {
      setInternalIsOpen(prevState => !prevState);
    }
  };

  const isOpen = externalControls ? externalControls.isOpen : internalIsOpen;

  const verticalSlideId = useId();

  return (
    <>
      <div className={classNames(styles.container, buttonContainerClassName)} role="heading" aria-level={ariaLevel}>
        <button className={styles.trigger} type="button" onClick={toggleOnClick} aria-expanded={isOpen} aria-controls={verticalSlideId}>
          <div className={styles.headerContent}>
            {leadingVisual && <div className={styles.leadingVisual}>{leadingVisual}</div>}
            <div className={styles.headerText}>
              <div className={styles.heading}>
                <Typography className={styles.title} variant={headingTypography ?? 'text200'}>
                  {title}
                </Typography>
                {subtitle && (
                  <Typography className={styles.subtitle} variant="text100" color="secondary">
                    {subtitle}
                  </Typography>
                )}
              </div>
            </div>
          </div>
          <div>
            {variant === 'minimal' ? (
              <div className={styles.row}>
                {trailingText && <div className={styles.trailingText}>{trailingText}</div>}
                <Chevron size="sm" color="--color-text-secondary" orientation={isOpen ? 'up' : 'down'} />
              </div>
            ) : (
              <Chevron color="--color-text-secondary" orientation={isOpen ? 'up' : 'down'} />
            )}
          </div>
        </button>
      </div>
      <VerticalSlide id={verticalSlideId} className={contentClassName} ignoreInitialOpeningAnimation={initiallyOpen} isOpen={isOpen}>
        {children}
      </VerticalSlide>
      <div className={classNames(styles.borderBottomContainer, borderBottomContainerClassName)}>
        <div className={styles.borderBottom} />
      </div>
    </>
  );
}
