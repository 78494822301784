import { ReactNode, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useAnimateInOutComponent } from '@alltrails/core';
import IconRenderer, { IconDefinition } from '../IconRenderer';
import styles from './styles/styles.module.scss';

type ToastProps = {
  action?: () => void;
  actionText?: ReactNode;
  className?: string;
  style?: React.CSSProperties;
  icon?: IconDefinition<'orientation'>;
  isOpen: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  timeout?: number;
  message: ReactNode;
  position?: 'primary' | 'alternative';
  type: 'success' | 'error';
  testId: string;
};

// https://www.figma.com/file/9Y1n3VoMJAcTGPe82bvySj/Denali-Components?type=design&node-id=684-21498&mode=design&t=1GCbgCDntmGeyIP0-0
export default function Toast({
  action,
  actionText,
  className,
  style,
  icon,
  isOpen,
  setIsOpen,
  timeout = 4000,
  message,
  position = 'primary',
  type,
  testId
}: ToastProps) {
  const { isAnimating, isVisible, onAnimationEnd } = useAnimateInOutComponent(isOpen);

  const actionContent = useMemo(
    () =>
      action && actionText ? (
        <button className={styles.actionLink} onClick={action} type="button">
          {actionText}
        </button>
      ) : null,
    [action, actionText]
  );

  const content = useMemo(
    () =>
      icon ? (
        <div className={styles.iconContainer}>
          <IconRenderer icon={icon} defaults={{ color: '--color-text-primary-inverse', size: 'sm' }} />
          <span className={classNames(styles.message, styles.truncating)}>{message}</span>
        </div>
      ) : (
        <span className={styles.truncating}>{message}</span>
      ),
    [icon, message]
  );

  // https://www.figma.com/design/9Y1n3VoMJAcTGPe82bvySj/Denali-Components?node-id=11700-15765&t=WOrh8Ln2nD5DSieW-0
  useEffect(() => {
    let timer;

    if (isOpen && setIsOpen) {
      timer = setTimeout(() => {
        setIsOpen(false);
      }, timeout);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isOpen, setIsOpen, timeout]);

  if (!isVisible && !isAnimating) {
    return null;
  }

  return (
    <div
      className={classNames(className, styles.toast, styles[position], styles[type], {
        [styles.textOnly]: !actionContent && !icon,
        [styles.isVisible]: isVisible,
        [styles.isAnimating]: isAnimating
      })}
      style={style}
      data-testid={testId}
      onAnimationEnd={onAnimationEnd}
    >
      {content}
      {actionContent}
    </div>
  );
}
