import Icon from '../components/Icon';

const Time = Icon(
  'time',
  <>
    <path d="M12.75 6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75h5a.75.75 0 0 0 0-1.5h-4.25z" />
    <path
      fillRule="evenodd"
      d="M12 1.25C6.063 1.25 1.25 6.063 1.25 12S6.063 22.75 12 22.75 22.75 17.937 22.75 12 17.937 1.25 12 1.25M2.75 12a9.25 9.25 0 1 1 18.5 0 9.25 9.25 0 0 1-18.5 0"
      clipRule="evenodd"
    />
  </>
);

export default Time;
