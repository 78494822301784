'use client';

import { useCallback, useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import useUser from '@alltrails/context/hooks/useUser';
import useInstallPrompt from '@alltrails/install-prompt/hooks/useInstallPrompt';
import InstallPromptLocation from '@alltrails/analytics/enums/InstallPromptLocation';
import { useLocalStorage } from '@alltrails/local-storage';
import UpsellTrigger from '@alltrails/analytics/enums/UpsellTrigger';
import { DeeplinkData } from '@alltrails/install-prompt/src/types';

const SignUpModal = dynamic(() => import('../SignUpModal').then(module => module));
const InstallInterstitial = dynamic(() => import('@alltrails/install-prompt/components/InstallInterstitial').then(module => module));
const PostAuthInterstitial = dynamic(() => import('@alltrails/install-prompt/components/PostAuthInterstitial'), { ssr: false });

const scrollLimitKey = 'scroll-limit-reached';
const pageLimitReached = 'page-limit-reached';

/* 
  Triggers the signup modal when a scroll threshold is reached.
  On mobile web the install interstitial is not displayed over the signup modal the first time the limit is reached, but is displayed on subsequent scroll limits.
*/
const ScrollLimit = ({ deeplinkData }: { deeplinkData?: DeeplinkData }) => {
  const { getValue: getScrollLimitReached, setValue: setScrollLimitReached } = useLocalStorage<boolean>(scrollLimitKey, false);
  const { getValue: getPageLimitReached, setValue: setPageLimitReached } = useLocalStorage<boolean>(pageLimitReached, false);

  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const [hideInterstitial, setHideInterstitial] = useState(false);
  const didLoginRef = useRef(false);
  const user = useUser();
  const [isInterstitialOpen, setIsInterstitialOpen] = useInstallPrompt('interstitial', false);
  const [isPostAuthInterstitialOpen, setIsPostAuthInterstitialOpen] = useInstallPrompt('interstitial', false);
  const disableScrollListenerRef = useRef(false);

  useEffect(() => {
    if (user) {
      return;
    }

    // Disable scroll restoration behavior to prevent scroll limit loop.
    if (history.scrollRestoration) {
      history.scrollRestoration = 'manual';
    }

    if (isSignupModalOpen) {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }

    const scrollThreshold = window.innerHeight;
    const onScroll = () => {
      if (disableScrollListenerRef.current) {
        return;
      }

      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const thresholdReached = scrollTop >= scrollThreshold;

      if (!isSignupModalOpen && thresholdReached) {
        if (!getScrollLimitReached()) {
          setHideInterstitial(true);
          setScrollLimitReached(true);
        } else {
          setHideInterstitial(false);
        }
        setIsSignupModalOpen(true);
      }

      if (isSignupModalOpen && !thresholdReached) {
        setIsSignupModalOpen(false);
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [getScrollLimitReached, isSignupModalOpen, setScrollLimitReached, user]);

  useEffect(() => {
    if (getPageLimitReached() && !user) {
      setIsInterstitialOpen(true);
    }

    if (!getPageLimitReached()) {
      setPageLimitReached(true);
    }
  }, [user]);

  const handleCloseModal = useCallback(() => {
    // Disable the scroll listener when autoscrolling to the top of the page.
    if (!didLoginRef.current) {
      disableScrollListenerRef.current = true;
      window.scrollTo(0, 0);
      setTimeout(() => {
        disableScrollListenerRef.current = false;
      }, 2000);
    }
    setIsSignupModalOpen(false);

    if (didLoginRef.current) {
      setIsPostAuthInterstitialOpen(true);
    }
  }, [user]);

  return (
    <>
      <SignUpModal
        autoClose
        deeplinkData={deeplinkData}
        hideInstallPrompt={hideInterstitial}
        trigger={CarouselDisplayTrigger.ScrollAuth}
        isOpen={isSignupModalOpen}
        closeModal={handleCloseModal}
        onSuccess={() => {
          didLoginRef.current = true;
          setIsSignupModalOpen(false);
        }}
        upsellTrigger={UpsellTrigger.ScrollAuth}
      />
      <InstallInterstitial
        isOpen={isInterstitialOpen}
        onRequestClose={() => setIsInterstitialOpen(false)}
        promptLocation={InstallPromptLocation.TrailPage}
        displayTrigger={CarouselDisplayTrigger.TrailDetailsWall3rdUniqueViewed}
        deeplinkData={deeplinkData}
        variant="generic"
      />
      <PostAuthInterstitial
        isOpen={isPostAuthInterstitialOpen}
        onRequestClose={() => setIsPostAuthInterstitialOpen(false)}
        isScrollLimitActive
        deeplinkData={deeplinkData}
      />
    </>
  );
};

export default ScrollLimit;
