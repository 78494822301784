'use client';

import { useRef } from 'react';
import useLogWebSectionViewed from '@alltrails/layout/hooks/useLogWebSectionViewed';
import PageSectionType from '@alltrails/analytics/enums/PageSectionType';
import PageType from '@alltrails/analytics/enums/PageType';

type Props = {
  children: React.ReactNode;
  pageType: PageType;
};

const ParkSectionWrapper = ({ children, pageType }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  useLogWebSectionViewed(ref, PageSectionType.VisitorInfo, pageType, { threshold: 0.6 });

  return <div ref={ref}>{children}</div>;
};

export default ParkSectionWrapper;
