import classNames from 'classnames';
import { type ComponentProps } from 'react';
import type { LinkInfo, Size } from '../../types';
import Avatar from '../Avatar';
import CustomAvatar from '../Avatar/CustomAvatar';
import LinkButtonOrDiv from '../LinkButtonOrDiv';
import IconButton from '../IconButton';
import styles from './styles/styles.module.scss';

type AvatarStackProps = {
  avatars: Pick<ComponentProps<typeof Avatar>, 'linkInfo' | 'onClick' | 'userId' | 'userName' | 'hasPlus'>[]; // linkInfo and onClick are ignored when size is xs
  totalAvatars?: number;
  baseUrl?: string;
  className?: string;
  maxAvatars?: number;
  linkInfo?: LinkInfo; // Applied to just the overflow element when size is greater than xs
  onClick?: () => void; // Applied to just the overflow element when size is greater than xs
  size?: Size<'xs' | 'sm' | 'md'>;
  testId: string;
  customCTA?: Pick<ComponentProps<typeof IconButton>, 'title' | 'onClick' | 'icon' | 'testId' | 'variant'>;
  buttonClassName?: string;
};

export default function AvatarStack({
  avatars,
  totalAvatars,
  baseUrl,
  className,
  maxAvatars = 5,
  linkInfo,
  onClick,
  size = 'md',
  testId,
  customCTA,
  buttonClassName
}: AvatarStackProps) {
  const visibleAvatars = Math.max(maxAvatars, 1); // Don't accept a maxAvatars value < 1
  const hiddenAvatars = totalAvatars ? totalAvatars - visibleAvatars : avatars.length - visibleAvatars;

  const overflowElement = () => {
    // Per design adding customCTA only on small size
    if (customCTA && size === 'sm') {
      return (
        <div className={classNames(styles.overflowCTA, buttonClassName)}>
          <IconButton {...customCTA} size="sm" />
        </div>
      );
    }
    if (hiddenAvatars > 0) {
      return (
        <CustomAvatar
          key="AvatarStack-overflow"
          className={classNames(styles.overflowAvatar, buttonClassName)}
          bordered
          linkInfo={size !== 'xs' ? linkInfo : undefined}
          onClick={size !== 'xs' ? onClick : undefined}
          size={size}
          testId={`${testId}-overflow`}
        >
          {hiddenAvatars < 100 ? `+${hiddenAvatars}` : '99+'}
        </CustomAvatar>
      );
    }
    return null;
  };

  const content = (
    <>
      {avatars.slice(0, visibleAvatars).map(({ linkInfo: avatarLinkInfo, onClick: avatarOnClick, userId, userName, hasPlus }) => (
        <Avatar
          key={userId}
          className={classNames(styles.avatar, buttonClassName)}
          baseUrl={baseUrl}
          bordered
          linkInfo={size !== 'xs' ? avatarLinkInfo : undefined}
          onClick={size !== 'xs' ? avatarOnClick : undefined}
          size={size}
          userId={userId}
          userName={userName}
          hasPlus={hasPlus}
          testId={`${testId}-${userId}`}
        />
      ))}
      {overflowElement()}
    </>
  );

  const classes = classNames(className, styles.container, styles[size]);

  if (size === 'xs') {
    return (
      <LinkButtonOrDiv className={classes} linkInfo={linkInfo} onClick={onClick} testId={testId}>
        {content}
      </LinkButtonOrDiv>
    );
  }

  return (
    <div className={classes} data-testid={testId}>
      {content}
    </div>
  );
}
