import { ComponentProps, ReactNode, useEffect, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { defineMessages, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import ReportLocation from '@alltrails/analytics/enums/ReportLocation';
import ReportContentType from '@alltrails/analytics/enums/ReportContentType';
import Link from '@alltrails/denali/components/Link';
import Toast from '@alltrails/denali/components/Toast';
import Check from '@alltrails/denali/icons/Check';
import type Review from '@alltrails/shared/types/review';
import type Context from '@alltrails/core/types/Context';
import type User from '@alltrails/core/types/User';
import SuccessToast from '@alltrails/shared/types/successToast';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import type { LightboxPhoto, LightboxUser } from '@alltrails/shared/types/lightbox';
import type ReviewPhoto from '@alltrails/shared/types/ReviewPhoto';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import compareUserIds from '@alltrails/shared/utils/compareUserIds';
import hasPermission from '@alltrails/shared/utils/hasPermission';
import { User as BelayUser } from '@alltrails/shared/types/belay';
import { EntryPoint } from '@alltrails/reporting/types';
import { useDeleteReviewMutation, useCreateReplyMutation, useUpdateReplyMutation, useDeleteReplyMutation } from '../../slices/reviewsApi';
import { TrailReview } from '../TrailReview';
import styles from './ReviewItem.module.scss';

const ReportIssueModal = dynamic(() => import('@alltrails/reporting/components/ReportIssueModal'), { ssr: false });
const AlertDialog = dynamic(() => import('@alltrails/denali/components/AlertDialog'), { ssr: false });

const messages = defineMessages({
  DELETE_REVIEW: { defaultMessage: 'Delete review?' },
  DELETING_REVIEW_SUBTEXT: { defaultMessage: 'Deleting a review will erase it permanently.' },
  DELETE: { defaultMessage: 'Delete' },
  KEEP: { defaultMessage: 'Keep' }
});

export type ReviewItemProps = {
  review: Review;
  index: number;
  languageRegionCode: LanguageRegionCode;
  onActivityLinkClick: (review: Review) => void;
  includeTrailName?: boolean;
  onDeleteReview?: (review: Review) => void;
  onLightboxClose?: () => void;
  onLightboxPhotoViewed?: (review: Review, photo: LightboxPhoto['photo']) => void;
  onLightboxUserClick?: (user: LightboxUser) => void;
  onReviewPhotosScroll?: (review: Review, numPhotos: number) => void;
  onReviewPhotosScrollEnd?: (review: Review, numPhotos: number) => void;
  openReviewFormModal?: (trailFormProps: any) => void;
  onUpdateReview?: (data: { detail: Review }) => void;
  onReviewPhotoClick?: (review: Review, photo: ReviewPhoto) => void;
  preferHTML?: boolean;
  photosClassName?: string;
  hideBorderBottom?: boolean;
  ReviewRenderer?: (props: ComponentProps<typeof TrailReview>) => JSX.Element;
  trailLinkNewTab?: boolean;
  onUserClick?: (user: User) => void;
  localizeOnLoad?: boolean;
  context?: Context;
  analyticsReportLocation?: ReportLocation;
  onToggleSummaryExclusion?: (review: Review, summaryExclusion: boolean) => void;
  photosOverflowOnMobile?: boolean;
  bottomLinkType?: 'trail' | 'activity';
  onTrailLinkClick?: () => void;
};

const ReviewItem = ({
  review,
  index,
  languageRegionCode,
  onActivityLinkClick,
  includeTrailName,
  onDeleteReview,
  onLightboxClose,
  onLightboxPhotoViewed,
  onLightboxUserClick,
  onReviewPhotosScroll,
  onReviewPhotosScrollEnd,
  openReviewFormModal,
  onUpdateReview,
  onReviewPhotoClick,
  preferHTML,
  photosClassName,
  hideBorderBottom,
  ReviewRenderer,
  trailLinkNewTab,
  onUserClick,
  localizeOnLoad = false,
  context,
  analyticsReportLocation,
  onToggleSummaryExclusion,
  photosOverflowOnMobile,
  bottomLinkType = 'activity',
  onTrailLinkClick
}: ReviewItemProps) => {
  const {
    formattedDefaultMessages: { DELETE_REVIEW, DELETING_REVIEW_SUBTEXT, DELETE, KEEP }
  } = useFormatMessage(messages);
  const [openAlertDialog, setIsAlertDialogOpen] = useState(false);
  const [openReportingModal, setIsReportingModalOpen] = useState(false);
  const [reportProps, setReportProps] = useState<{ trailId: string; reviewId: string; reportUser: User | BelayUser } | null>(null);
  const [reportingSuccessToast, setReportingSuccessToast] = useState<SuccessToast>({ isVisible: false, text: '' });

  const [deleteReview] = useDeleteReviewMutation();
  const [createReply] = useCreateReplyMutation();
  const [updateReply] = useUpdateReplyMutation();
  const [deleteReply] = useDeleteReplyMutation();

  useEffect(() => {
    if (reportingSuccessToast.isVisible) {
      setTimeout(() => {
        setReportingSuccessToast({ isVisible: false, text: '' });
      }, 3000);
    }
  });

  const handleDeleteReviewClick = () => {
    setIsAlertDialogOpen(true);
  };

  const handleOnCloseDeleteReviewClick = () => {
    setIsAlertDialogOpen(false);
  };

  const onDeleteClick = () => {
    deleteReview({ userId: review.user.id, reviewId: review.id })
      .unwrap()
      .then(() => {
        onDeleteReview?.(review);
      })
      .catch((e: Error) => console.log(`Failed to delete review: ${e?.message}`));
    return false;
  };

  const handleOpenReviewFormModal = () => {
    openReviewFormModal?.(review);
  };

  const changeRepliesSuccessCallback = (xhr: any) => {
    if (onUpdateReview && xhr.trail_reviews && xhr.trail_reviews.length > 0) {
      onUpdateReview({ detail: xhr.trail_reviews[0] });
    }
  };

  const handleDeleteReplyClick = () => {
    const { replies } = review;
    if (replies && replies.length > 0) {
      deleteReply({ review })
        .unwrap()
        .then(response => {
          changeRepliesSuccessCallback(response);
        })
        .catch((e: Error) => console.log(`Failed to delete reply: ${e?.message}`));
    }
  };

  const handleReplySave = async (comment: string) => {
    const { replies } = review;
    if (replies && replies.length > 0) {
      await updateReply({ review, comment })
        .unwrap()
        .then(response => {
          changeRepliesSuccessCallback(response);
        })
        .catch((e: Error) => console.log(`Failed to update reply: ${e?.message}`));
    } else {
      await createReply({ review, comment })
        .unwrap()
        .then(response => {
          changeRepliesSuccessCallback(response);
        })
        .catch((e: Error) => console.log(`Failed to create reply: ${e?.message}`));
    }
  };

  const trailLink = (str: ReactNode) => {
    const { trailSlug } = review;

    return (
      <Link
        href={`/trail/${trailSlug}`}
        {...(trailLinkNewTab ? { target: '_blank', rel: ['noopener', 'noreferrer'] } : {})}
        size="md"
        testId={`reviewed-${trailSlug}-link`}
      >
        {str}
      </Link>
    );
  };

  const getReviewNameExtension = () => {
    if (includeTrailName) {
      const { trailName, trailSlug } = review;
      if (trailName && trailSlug) {
        return <FormattedMessage defaultMessage="reviewed <link>{trail}</link>" values={{ link: trailLink, trail: trailName }} />;
      }
    }
    return null;
  };

  const onClickUser = onUserClick ? () => onUserClick(review.user) : undefined;

  const handleToggleSummaryExclusion = (summaryExclusion: boolean) => onToggleSummaryExclusion?.(review, summaryExclusion);

  const ItemRenderer = useMemo(() => ReviewRenderer || TrailReview, [ReviewRenderer]);

  return (
    <div
      className={classNames(styles.reviewContainer, {
        [styles.hideBorderBottom]: hideBorderBottom,
        [styles.newReviewContainer]: true
      })}
    >
      <ItemRenderer
        currentUser={context?.currentUser || undefined}
        index={index}
        isEditable={
          compareUserIds(review.user.id, context?.currentUser?.id) || hasPermission({ permission: 'trails:manage', user: context?.currentUser })
        }
        languageRegionCode={languageRegionCode}
        nameExtension={getReviewNameExtension() || undefined}
        onActivityLinkClick={() => onActivityLinkClick(review)}
        onDeleteClick={handleDeleteReviewClick}
        onEditClick={handleOpenReviewFormModal}
        onLightboxClose={onLightboxClose}
        onLightboxPhotoViewed={onLightboxPhotoViewed}
        onLightboxUserClick={onLightboxUserClick}
        onReviewPhotosScroll={onReviewPhotosScroll}
        onReviewPhotosScrollEnd={onReviewPhotosScrollEnd}
        onReplyDeleteClick={handleDeleteReplyClick}
        onReplySaveClick={handleReplySave}
        onReviewPhotoClick={onReviewPhotoClick}
        preferHTML={preferHTML}
        photosClassName={photosClassName}
        review={review}
        onUserClick={onClickUser}
        localizeOnLoad={localizeOnLoad}
        setIsReportingModalOpen={setIsReportingModalOpen}
        setReportProps={setReportProps}
        setReportingSuccessToast={setReportingSuccessToast}
        context={context}
        onToggleSummaryExclusion={handleToggleSummaryExclusion}
        photosOverflowOnMobile={photosOverflowOnMobile}
        bottomLinkType={bottomLinkType}
        onTrailLinkClick={onTrailLinkClick}
      />
      {openAlertDialog && (
        <AlertDialog
          title={DELETE_REVIEW}
          body={DELETING_REVIEW_SUBTEXT}
          buttons={{
            primary: {
              onClick: () => {
                handleOnCloseDeleteReviewClick();
                onDeleteClick();
              },
              text: DELETE,
              testId: 'delete-review-confirm'
            },
            flat: {
              onClick: handleOnCloseDeleteReviewClick,
              text: KEEP,
              testId: 'delete-review-cancel'
            }
          }}
          onRequestClose={handleOnCloseDeleteReviewClick}
          size="sm"
        />
      )}
      {context && context?.currentUser && reportProps && setReportingSuccessToast && analyticsReportLocation && openReportingModal && (
        <ReportIssueModal
          analyticsData={{ location: analyticsReportLocation, contentType: ReportContentType.Review }}
          currentUserId={context.currentUser.id}
          entryPoint={EntryPoint.Reviews}
          isOpen={openReportingModal}
          onCloseRequest={() => setIsReportingModalOpen(false)}
          reportId={reportProps.trailId}
          reportUser={reportProps.reportUser}
          reportType="issue"
          setSuccessToast={setReportingSuccessToast}
          trailReviewId={reportProps.reviewId}
        />
      )}
      {reportingSuccessToast.isVisible && (
        <Toast
          isOpen
          message={reportingSuccessToast.text}
          position="alternative"
          type="success"
          testId="report-success-toast"
          icon={{ Component: Check }}
        />
      )}
    </div>
  );
};

export default ReviewItem;
