import Icon from '../components/Icon';

const ClosureFilled = Icon(
  'closure-filled',
  <>
    <path
      fillRule="evenodd"
      d="M12 1.25C6.063 1.25 1.25 6.063 1.25 12S6.063 22.75 12 22.75 22.75 17.937 22.75 12 17.937 1.25 12 1.25M9.53 8.47a.75.75 0 0 0-1.06 1.06l6 6a.75.75 0 1 0 1.06-1.06z"
      clipRule="evenodd"
    />
  </>
);

export default ClosureFilled;
