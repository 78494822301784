'use client';

import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import logWebTrailLinkClicked from '@alltrails/analytics/events/logWebTrailLinkClicked';
import { ReviewsBase } from '@alltrails/reviews';
import { ComponentProps, useRef, useState } from 'react';
import useUser from '@alltrails/context/hooks/useUser';
import ReportLocation from '@alltrails/analytics/enums/ReportLocation';
import dynamic from 'next/dynamic';
import PageSectionType from '@alltrails/analytics/enums/PageSectionType';
import useLogWebSectionViewed from '@alltrails/layout/hooks/useLogWebSectionViewed';
import Review, { ReviewId } from '@alltrails/shared/types/review';
import logPhotoCarouselSwiped from '@alltrails/analytics/events/logPhotoCarouselSwiped';
import PhotoCarouselType from '@alltrails/analytics/enums/PhotoCarouselType';
import layoutStyles from '../layouts/layoutStyles.module.scss';
import styles from './Reviews.module.scss';
import SocialProofing from './SocialProofing';
import { useSelector } from '@/utils/redux';
import { LocationDetailsResponseType, ReviewAndTrail, VisitorStats } from '@/types/locationDetailsPages/shared';
import { getAnalyticsPageType } from '@/utils/getAnalyticsPageType';

const ReviewFormModal = dynamic(() => import('@alltrails/reviews').then(module => ({ default: module.ReviewFormModal })));

type Props = {
  reviewsData: ReviewAndTrail[];
  locationName: string;
  type?: LocationDetailsResponseType;
  hasVisitorInfoExperiment?: boolean;
  visitorStats?: VisitorStats;
};

const reviewsToDisplay = 6;

const Reviews = ({ reviewsData, locationName, type, hasVisitorInfoExperiment, visitorStats }: Props) => {
  const pageType = getAnalyticsPageType(type);
  const user = useUser();
  const reviewsRef = useRef<HTMLDivElement>(null);
  const context = useSelector(state => state.context);
  const reviews = reviewsData?.slice(0, reviewsToDisplay).map?.(review => review.review_snippet);
  const [isReviewFormOpen, toggleReviewForm] = useState(false);
  const [reviewFormProps, setReviewFormProps] = useState<ComponentProps<typeof ReviewFormModal>>();
  const [currentTrailId, setCurrentTrailId] = useState<number | undefined>();
  const carouselSwipedRef = useRef(new Set<ReviewId>());
  const openReviewFormModal = (formProps: ComponentProps<typeof ReviewFormModal>) => {
    setReviewFormProps(formProps);
    toggleReviewForm(true);
    setCurrentTrailId(formProps.trailId);
  };
  const ref = useRef<HTMLDivElement>(null);
  useLogWebSectionViewed(ref, PageSectionType.TrailReviews, pageType);
  const onReviewPhotosScroll = (review: Review, numPhotos: number) => {
    if (!carouselSwipedRef.current.has(review.id)) {
      carouselSwipedRef.current.add(review.id);
      logPhotoCarouselSwiped({
        carousel_item_id: review.id.toString(),
        page_type: pageType,
        photo_carousel_type: PhotoCarouselType.Reviews
      });
    }
  };

  return (
    <div ref={ref}>
      <div className={styles.container}>
        <span className={styles.overview}>
          <h2 className={styles.reviewTitle}>
            <FormattedMessage defaultMessage="Trail reviews for" />
            <span className={styles.secondaryText}> {locationName}</span>
          </h2>
          {hasVisitorInfoExperiment && visitorStats && type === 'park' && <SocialProofing reviews={reviews} visitorStats={visitorStats} />}
        </span>
        <div className={classNames(layoutStyles.col1, styles.desktopSpacer)} />
        <div className={styles.ugcContainer}>
          <ReviewsBase
            ref={reviewsRef}
            context={context}
            hideSort
            localizeOnLoad
            onActivityLinkClick={() => {}}
            onLoadMore={() => {}}
            openReviewFormModal={openReviewFormModal}
            preferHTML
            reportLocation={ReportLocation.LocationWebPage}
            reviews={reviews}
            total={reviews.length}
            onReviewPhotosScroll={onReviewPhotosScroll}
            bottomLinkType="trail"
            onTrailLinkClick={() => {
              if (pageType) logWebTrailLinkClicked({ page_type: pageType, section_type: PageSectionType.TrailReviews });
            }}
          />
        </div>
        {user && isReviewFormOpen && (
          <ReviewFormModal
            activity={reviewFormProps?.activity}
            associatedRecording={reviewFormProps?.associatedRecording}
            associatedTrailReviewId={reviewFormProps?.associatedTrailReviewId}
            closeReviewModal={() => toggleReviewForm(false)}
            comment={reviewFormProps?.comment}
            comment_source={reviewFormProps?.comment_source}
            date={reviewFormProps?.date}
            difficulty={reviewFormProps?.difficulty}
            id={reviewFormProps?.id}
            infoAttributes={reviewFormProps?.infoAttributes}
            isActivity={reviewFormProps?.isActivity}
            obstacles={reviewFormProps?.obstacles}
            rating={reviewFormProps?.rating}
            ratingAttributes={reviewFormProps?.ratingAttributes}
            reviewSource={reviewFormProps?.reviewSource}
            trailId={currentTrailId}
            user={user}
          />
        )}
      </div>
    </div>
  );
};

export default Reviews;
