import Icon from '../components/Icon';

const CautionFilled = Icon(
  'caution-filled',
  <>
    <path
      fillRule="evenodd"
      d="M14.397 3.731c-1.051-1.869-3.742-1.869-4.794 0l-7.83 13.92c-1.031 1.834.294 4.1 2.397 4.1h15.66c2.104 0 3.428-2.266 2.397-4.1zM12 8.251a.75.75 0 0 1 .75.75v4a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75M12 16a1 1 0 1 0 0 2 1 1 0 0 0 0-2"
      clipRule="evenodd"
    />
  </>
);

export default CautionFilled;
