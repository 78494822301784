'use client';

import dynamic from 'next/dynamic';
import useInstallPrompt from '@alltrails/install-prompt/hooks/useInstallPrompt';
import InstallPromptLocation from '@alltrails/analytics/enums/InstallPromptLocation';
import { LocationDetailsPageProps } from '@/types/locationDetailsPages/shared';

const DynamicInstallBanner = dynamic(() => import('@alltrails/install-prompt/components/InstallBanner'), { ssr: false });

export default function DynamicInstallBannerWrapper({ details }: Pick<LocationDetailsPageProps, 'details'>) {
  const [isBannerOpen, setIsBannerOpen] = useInstallPrompt('banner', true);
  const deeplinkData = details.parkInfo?.parkId ? { pageId: details.parkInfo?.parkId, pageType: 'area' } : {};

  return (
    <>
      <DynamicInstallBanner
        {...deeplinkData}
        isOpen={isBannerOpen}
        onRequestClose={() => setIsBannerOpen(false)}
        promptLocation={InstallPromptLocation.ParksPage}
      />
    </>
  );
}
