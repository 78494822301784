import { ComponentProps, ReactNode, Ref, createRef, forwardRef, useEffect } from 'react';
import classNames from 'classnames';
import ReportLocation from '@alltrails/analytics/enums/ReportLocation';
import useIsVisible from '@alltrails/shared/hooks/useIsVisible';
import type User from '@alltrails/core/types/User';
import type Context from '@alltrails/core/types/Context';
import type { LightboxPhoto, LightboxUser } from '@alltrails/shared/types/lightbox';
import type Review from '@alltrails/shared/types/review';
import type ReviewPhoto from '@alltrails/shared/types/ReviewPhoto';
import { TrailReview } from '../TrailReview';
import ShowMore from '../ShowMore/ShowMore';
import ReviewItem from '../ReviewItem/ReviewItem';
import ReviewsSort from '../ReviewsSort/ReviewsSort';
import type { SortOption } from '../../types';
import styles from './ReviewsBase.module.scss';

export type ReviewsBaseProps = {
  ads?: ReactNode[];
  canLoadMore?: boolean;
  context: Context;
  emptyState?: ReactNode;
  hideSort?: boolean;
  includeTrailName?: boolean;
  infiniteScroll?: boolean;
  isLoadingMore?: boolean;
  localizeOnLoad?: boolean;
  onActivityLinkClick: (review: Review) => void;
  onDeleteReview?: (review: Review) => void;
  onLightboxClose?: () => void;
  onLightboxPhotoViewed?: (review: Review, photo: LightboxPhoto['photo']) => void;
  onLightboxUserClick?: (user: LightboxUser) => void;
  onLoadMore: () => void;
  onReviewPhotoClick?: (review: Review, photo: ReviewPhoto) => void;
  onReviewPhotosScroll?: (review: Review, numPhotos: number) => void;
  onReviewPhotosScrollEnd?: (review: Review, numPhotos: number) => void;
  onSortChange?: (oldSort: SortOption, newSort: SortOption) => void;
  onToggleSummaryExclusion?: (review: Review, summaryExclusion: boolean) => void;
  onUpdateReview?: (data: { detail: Review }) => void;
  onUserClick?: (user: User) => void;
  openReviewFormModal?: (trailFormProps: any) => void;
  photosClassName?: string;
  preferHTML?: boolean;
  reportLocation?: ReportLocation;
  resultsFooter?: ReactNode;
  ReviewRenderer?: (props: ComponentProps<typeof TrailReview>) => JSX.Element;
  reviews: Review[];
  sortOption?: SortOption;
  stickySort?: boolean;
  total: number;
  trailLinkNewTab?: boolean;
  photosOverflowOnMobile?: boolean;
  bottomLinkType?: 'trail' | 'activity';
  onTrailLinkClick?: () => void;
};

/**
 * An uncontrolled reviews component.
 */
const ReviewsBase = forwardRef(
  (
    {
      ads,
      canLoadMore,
      context,
      emptyState,
      hideSort,
      includeTrailName,
      infiniteScroll,
      isLoadingMore,
      localizeOnLoad,
      onActivityLinkClick,
      onDeleteReview,
      onLightboxClose,
      onLightboxPhotoViewed,
      onLightboxUserClick,
      onLoadMore,
      onReviewPhotoClick,
      onReviewPhotosScroll,
      onReviewPhotosScrollEnd,
      onSortChange,
      onToggleSummaryExclusion,
      onUpdateReview,
      onUserClick,
      openReviewFormModal,
      preferHTML,
      photosClassName,
      reportLocation,
      resultsFooter,
      reviews,
      ReviewRenderer,
      sortOption,
      stickySort,
      total,
      trailLinkNewTab,
      photosOverflowOnMobile,
      bottomLinkType = 'activity',
      onTrailLinkClick
    }: ReviewsBaseProps,
    ref: Ref<HTMLDivElement>
  ): JSX.Element => {
    const lastSection = createRef<HTMLDivElement>();
    const isLastSectionVisible = useIsVisible(lastSection);

    const onSortChangeInternal = (newSort: SortOption) => {
      if (hideSort) return;
      onSortChange?.(sortOption!, newSort);
    };

    /**
     * Support for infinite scrolling
     */
    useEffect(() => {
      if (infiniteScroll && isLastSectionVisible && reviews.length && reviews.length < total) {
        onLoadMore();
      }
    }, [infiniteScroll, isLastSectionVisible, onLoadMore, reviews.length, total]);

    return (
      <div ref={ref} tabIndex={-1} className={classNames({ [styles.stickyWrapper]: stickySort })}>
        {!hideSort && sortOption && (
          <div className={classNames(styles.sort, { [styles.sticky]: stickySort })}>
            <ReviewsSort onSortChanged={onSortChangeInternal} sortOption={sortOption} sticky={stickySort} />
          </div>
        )}
        {!!reviews.length &&
          reviews.map((review, idx) => (
            <div key={review.id} ref={idx === reviews.length - 1 ? lastSection : undefined}>
              <ReviewItem
                analyticsReportLocation={reportLocation}
                context={context}
                includeTrailName={includeTrailName}
                index={idx}
                languageRegionCode={context.languageRegionCode}
                localizeOnLoad={localizeOnLoad}
                onActivityLinkClick={onActivityLinkClick}
                onDeleteReview={onDeleteReview}
                onLightboxClose={onLightboxClose}
                onLightboxPhotoViewed={onLightboxPhotoViewed}
                onLightboxUserClick={onLightboxUserClick}
                onReviewPhotoClick={onReviewPhotoClick}
                onReviewPhotosScroll={onReviewPhotosScroll}
                onReviewPhotosScrollEnd={onReviewPhotosScrollEnd}
                onToggleSummaryExclusion={onToggleSummaryExclusion}
                onUpdateReview={onUpdateReview}
                onUserClick={onUserClick}
                openReviewFormModal={openReviewFormModal}
                preferHTML={preferHTML}
                photosClassName={photosClassName}
                ReviewRenderer={ReviewRenderer}
                review={review}
                trailLinkNewTab={trailLinkNewTab}
                photosOverflowOnMobile={photosOverflowOnMobile}
                bottomLinkType={bottomLinkType}
                onTrailLinkClick={onTrailLinkClick}
              />
              {idx === 4 && ads?.[0]}
              {idx === 14 && ads?.[1]}
              {idx === 24 && ads?.[2]}
            </div>
          ))}
        {!reviews.length && emptyState}
        {resultsFooter}
        {!infiniteScroll && canLoadMore && <ShowMore isLoading={!!isLoadingMore} onLoadMore={onLoadMore} />}
      </div>
    );
  }
);

export default ReviewsBase;
